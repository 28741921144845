import * as React from "react";
import HaveYouSeenSection from "../../../components/HaveYouSeenSection/HaveYouSeenSection";
import Layout from "../../../components/Layout/Layout";
import TeamRolesSection from "../../../components/TeamRolesSection/TeamRolesSection";
import TestimonialSection from "../../../components/TestimonialSection/TestimonialSection";
import SubPageTitle from "../../../components/SubPageTitle/SubPageTitle";
import { earlyCareers } from "../../../components/SubPageTitle/subPageTitleContent";
import { earlyCareersRoles } from "../../../components/TeamRolesSection/TeamRolesSectionContent";
import VarsitySection from "../../../components/VarsitySection/VarsitySection";
import { varsitySection } from "../../../components/VarsitySection/VarsitySectionContent";
import { SEO } from "../../../components/seo";
import { descriptions } from "../../../libs/seoContent";

const EarlyCareers = () => {
  return (
    <Layout>
      {/* This the dummy page for you to put your components in */}
      <SubPageTitle content={earlyCareers} />
      <VarsitySection content={varsitySection} />
      <TeamRolesSection content={earlyCareersRoles} />
      <TestimonialSection pageTitle={"Early Careers"} className="reduced-padding-bottom" />
      <HaveYouSeenSection page="earlyCareers" />
    </Layout>
  );
};

export default EarlyCareers;

export const Head = () => <SEO title="Early Careers" description={descriptions.earlyCareers} />;
